import React from "react"
import InfoModal from "./info-modal"
import Text from "@ui/Text/Text"

const ExpertNetworkResponsibilityModal = () => {
  return (
    <InfoModal
      title="Expert Network - Team Member - Responsibility"
      iconLabel="Expert Network - Team Member - Responsibility"
    >
      <Text>
        Supporting the development of the NDP at various stages by e.g.
        providing information, data and other relevant input to ensure key
        sectoral and territorial aspects are included in the NDP that help
        support coherence across the… (missing text)
      </Text>
    </InfoModal>
  )
}

export default ExpertNetworkResponsibilityModal
