import React from "react"
import shouldForwardProp from "@styled-system/should-forward-prop"

import {
  color,
  ColorProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system"

import styled from "@emotion/styled"

type UIProps = ColorProps & SpaceProps & TypographyProps

const StyledHeading = styled("h2", { shouldForwardProp })<UIProps>`
  margin: 0;
  font-weight: normal;

  ${space}
  ${typography}
  ${color}
`
export type HeadingProps = React.HTMLAttributes<HTMLElement> &
  UIProps & { size?: string; as?: any }

const Heading = styled(
  React.forwardRef(
    (
      { size = "xl", ...props }: HeadingProps,
      ref: React.Ref<HTMLHeadingElement>
    ) => {
      return <StyledHeading ref={ref} fontSize={size} {...props} />
    }
  )
)()

export default Heading
