import shouldForwardProp from "@styled-system/should-forward-prop"
import React from "react"
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from "styled-system"

import styled from "@emotion/styled"
import { shade } from "polished"

export type StyleProps = LayoutProps & SpaceProps & ColorProps & TypographyProps

export const StyledButton = styled("button", {
  shouldForwardProp,
})<StyleProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 1rem;
  background: transparent;
  border: 0;
  cursor: pointer;

  text-decoration: none;

  transition: all 0.2s ease-in-out;
  outline: none;

  &:disabled {
    opacity: 0.35;
    pointer-events: none;
  }

  &:hover,
  &:focus {
    outline: 0;
  }

  ${(props) =>
    variant({
      variants: {
        primary: {
          backgroundColor: props.theme.colors.rhino,
          color: "white",
          "&:hover, &:focus": {
            backgroundColor: shade(0.15, props.theme.colors.rhino),
          },
        },
      },
    })}

  ${layout};
  ${space};
  ${color};
  ${typography};
`

export type Props = StyleProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    children?: React.ReactNode | string
    variant?: "primary"
    as?: any
  }

const Button = ({ onChange, value, name, id, ...props }: any) => {
  return (
    <StyledButton
      onChange={onChange}
      value={value}
      name={name}
      id={id}
      {...props}
    />
  )
}

Button.defaultProps = {
  px: "lg",
  height: "3xl",
}

export default Button
