import shouldForwardProp from "@styled-system/should-forward-prop"
import React from "react"

import styled from "@emotion/styled"

import { StyledInput, StyleProps } from "../Input/Input"

const StyledTextarea = styled(StyledInput.withComponent("textarea"), {
  shouldForwardProp,
})`
  resize: none;
`

export type Props = StyleProps &
  React.InputHTMLAttributes<HTMLTextAreaElement> & {
    children?: React.ReactNode | string
    invalid?: boolean
  }

const Textarea = ({
  children,
  onChange,
  value,
  name,
  id,
  invalid,
  ...props
}: Props) => {
  return (
    <StyledTextarea
      onChange={onChange}
      value={value}
      name={name}
      id={id}
      data-invalid={invalid}
      {...props}
    />
  )
}

Textarea.defaultProps = {
  p: "md",
  minHeight: "7rem",
}

export default Textarea
