import React from "react"
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer"
import { TeamsState } from "@ducks/teamsSlice"
import { Styles } from "polished/lib/types/style"

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
  },
})

type Props = TeamsState

const ViewWrapper = (style: Styles) => (props: any) => (
  <View {...props} style={{ ...style, ...props.style }} />
)
const TextWrapper = (style: Styles) => (props: any) => (
  <Text {...props} style={{ ...style, ...props.style }} />
)

const PageTitle = TextWrapper({
  fontSize: 24,
  borderBottom: "1",
  borderBottomColor: "#12296B",
  paddingBottom: 16,
  margin: 16,
})

const PageSection = ViewWrapper({
  padding: 16,
  fontSize: 11,
  width: "100%",
})

const Header = ViewWrapper({
  flexDirection: "row",
  marginTop: 16,
  justifyContent: "space-between",
  marginBottom: 4,
  paddingRight: 16,
})

const HeaderColumn = TextWrapper({
  width: "40%",
  flexShrink: 0,
  textAlign: "center",
  backgroundColor: "#12296B",
  color: "white",
  verticalAlign: "center",
  textTransform: "uppercase",
  padding: 8,
  fontSize: 9,
})

const TeamRow = ViewWrapper({
  flexDirection: "row",
  width: "100%",
  paddingRight: 16,
  justifyContent: "space-between",
})

const TeamColumn = TextWrapper({
  width: "20%",
  flexShrink: 0,
  backgroundColor: "#12296B",
  padding: 16,
  marginTop: 4,
  marginBottom: 4,
  color: "white",
})

const DetailsColumn = ViewWrapper({
  flexDirection: "column",
  width: "50%",
  flexShrink: 0,
  margin: 4,
  backgroundColor: "#E7EAF0",
  padding: 16,
})

const MemberRow = ViewWrapper({
  flexDirection: "row",
  justifyContent: "space-between",
})

const MemberColumn = ViewWrapper({
  flexDirection: "column",
  width: "80%",
  flexShrink: 0,
  marginLeft: 4,
  marginRight: 4,
})

// Create Document Component
const TeamsPdf = ({ core, experts, extended }: Props) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PageTitle>
          Institutional Structure for the Development of the NDP
        </PageTitle>

        <PageSection>
          <Header>
            <HeaderColumn style={{ width: "20%" }}>Team</HeaderColumn>
            <HeaderColumn
              style={{
                marginLeft: 8,
                marginRight: 4,
              }}
            >
              Members
            </HeaderColumn>
            <HeaderColumn
              style={{
                marginLeft: 4,
                marginRight: 4,
              }}
            >
              Responsibilities
            </HeaderColumn>
          </Header>
          <TeamRow>
            <TeamColumn>Core Team</TeamColumn>
            <MemberColumn>
              {core.map((member, index) => (
                <MemberRow key={index}>
                  <DetailsColumn>
                    <Text>{member.name}</Text>
                    <Text>{member.title}</Text>
                    <Text>{member.teamTitle}</Text>
                    <Text>{member.organizationalUnit}</Text>
                  </DetailsColumn>
                  <DetailsColumn>
                    <Text>{member.responsibility}</Text>
                  </DetailsColumn>
                </MemberRow>
              ))}
            </MemberColumn>
          </TeamRow>
          <TeamRow>
            <TeamColumn>Extended Team</TeamColumn>
            <MemberColumn>
              {extended.map((member, index) => (
                <MemberRow key={index}>
                  <DetailsColumn>
                    <Text>{member.name}</Text>
                    <Text>{member.title}</Text>
                    <Text>{member.teamTitle}</Text>
                    <Text>{member.organizationalUnit}</Text>
                  </DetailsColumn>
                  <DetailsColumn>
                    <Text>{member.responsibility}</Text>
                  </DetailsColumn>
                </MemberRow>
              ))}
            </MemberColumn>
          </TeamRow>
          <TeamRow>
            <TeamColumn>Expert Network (Technical Working Groups</TeamColumn>
            <MemberColumn>
              {experts.map((member, index) => (
                <MemberRow key={index}>
                  <DetailsColumn>
                    <Text>{member.name}</Text>
                    <Text>{member.title}</Text>
                    <Text>{member.teamTitle}</Text>
                    <Text>{member.organizationalUnit}</Text>
                  </DetailsColumn>
                  <DetailsColumn>
                    <Text>{member.responsibility}</Text>
                  </DetailsColumn>
                </MemberRow>
              ))}
            </MemberColumn>
          </TeamRow>
        </PageSection>
      </Page>
    </Document>
  )
}

export default TeamsPdf
