import React from "react"
import InfoModal from "./info-modal"
import Text from "@ui/Text/Text"
import List from "@ui/List/List"
import ListItem from "@ui/List/ListItem"

const ExtendedTeamModal = () => {
  return (
    <InfoModal title="Extended Team" iconLabel="Extended Team">
      <Text>
        Representatives from Line- Ministries and Government Agencies,
        Representatives from Local authorities as well as members from the
        Statistical Commissions. In addition to members from traditional
        line-Ministries representing the social, economic and environmental
        sectors, key representatives to be included in the extended team are:
      </Text>
      <List>
        <ListItem>AU liason officers/Agenda 2063</ListItem>
        <ListItem>SDG Coordinators</ListItem>
        <ListItem>DRR Focal points</ListItem>
        <ListItem>Climate/NDC Focal points</ListItem>
        <ListItem>Gender focal points</ListItem>
        <ListItem>Human Rights focal points</ListItem>
      </List>
    </InfoModal>
  )
}

export default ExtendedTeamModal
