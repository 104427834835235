import shouldForwardProp from "@styled-system/should-forward-prop"
import { Link as GatsbyLink } from "gatsby"

import {
  color,
  ColorProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  layout,
  LayoutProps,
} from "styled-system"

import styled from "@emotion/styled"
import { rgba } from "polished"

type UIProps = ColorProps &
  SpaceProps &
  LayoutProps &
  TypographyProps & {
    as?: any
    "data-type"?: "invert"
  }

const Link = styled("a", {
  shouldForwardProp,
})<UIProps>`
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  text-decoration: none;
  display: inline;
  font-weight: 600;

  color: ${(props) => props.theme.colors.acapulcoDark};
  text-decoration: underline;

  &:hover,
  &:focus {
    outline: 0;
    background-color: ${(props) => rgba(props.theme.colors.acapulco, 0.1)};
  }

  [data-type="invert"] &,
  &[data-type="invert"] {
    color: #fff;
  }

  [data-type="invert"] &:hover,
  [data-type="invert"] &:focus,
  &[data-type="invert"]:hover,
  &[data-type="invert"]:focus {
    background-color: rgba(255, 255, 255, 0.1);
  }

  ${layout}
  ${space}
  ${typography}
  ${color}
`

Link.defaultProps = {
  target: "_blank",
}

export const AppLink = Link.withComponent(GatsbyLink)

AppLink.defaultProps = {
  target: "_self",
}

export default Link
