import shouldForwardProp from "@styled-system/should-forward-prop"
import base64 from "base-64"

import {
  color,
  ColorProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system"

import styled from "@emotion/styled"

type UIProps = ColorProps &
  SpaceProps &
  TypographyProps & {
    as?: any
  }

const svgDot = (color: string) =>
  `url("data:image/svg+xml;base64,` +
  base64.encode(
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 1" width="8" height="4"><circle fill="${color}" cx="1" cy="0.5" r="0.5"/></svg>`
  ) +
  `")`

const HorizontalRule = styled("hr", {
  shouldForwardProp,
})<UIProps>`
  border-width: 0 0 4px;
  border-style: solid;
  border-image: ${(props) =>
      svgDot(props.color || props.theme.colors.acapulcoDark)}
    0 0 100% repeat;

  margin: ${(props) => props.theme.space.lg} 0;
  ${space}
  ${typography} 
  ${color}
`

export default HorizontalRule
