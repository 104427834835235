import React from "react"
import {
  configureStore,
  Action,
  ActionCreator,
  EnhancedStore,
} from "@reduxjs/toolkit"
import thunk, { ThunkAction } from "redux-thunk"
import { persistReducer, persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import storage from "redux-persist/lib/storage"

import rootReducer, { RootState } from "./rootReducer"
import { Provider } from "react-redux"

export type AppThunk = ActionCreator<
  ThunkAction<void, RootState, null, Action<string>>
>

const persistConfig = {
  key: "root",
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const defaultStore = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
})

export const persistor = persistStore(defaultStore)

interface Props {
  store: EnhancedStore<RootState>
  element: React.ReactNode
}

export default ({ store = defaultStore, element }: Props) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {element}
    </PersistGate>
  </Provider>
)
