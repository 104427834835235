import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"
import * as R from "ramda"

type CheckboxState = boolean
type ModuleState = {
  [key: string]: CheckboxState
}

export type State = {
  [module: string]: ModuleState
}
type RootState = {
  checkbox: State
}
const initialState: State = {}

type CheckboxIdentifier = {
  module: string
  id: string
  step: string
}

type CheckboxCheckPayload = CheckboxIdentifier & {
  checked: boolean
}

const checklistSliceSelector = (state: RootState) => state.checkbox

export const isCheckboxCheckedSelector = createSelector(
  checklistSliceSelector,
  (_: any, props: CheckboxIdentifier) => props,
  (state, { module, id }) => (state[module] && state[module][id]) || false
)

export const CompletedModulesSelector = createSelector(
  checklistSliceSelector,
  R.pipe<State, { [module: string]: boolean }>(
    R.mapObjIndexed(
      R.pipe<ModuleState, CheckboxState[], CheckboxState[], any>(
        R.values,
        R.flatten,
        R.ifElse(
          R.isEmpty,
          () => false,
          R.all<CheckboxState>((check) => check)
        )
      )
    )
  )
)

const { reducer, actions } = createSlice({
  name: "checkbox",
  initialState,
  reducers: {
    setInitialState(state, action: PayloadAction<State>) {
      const updatedState = Object.assign({}, action.payload, state)
      Object.assign(state, updatedState)
    },

    checkCheckbox(
      state,
      { payload: { module, id, checked } }: PayloadAction<CheckboxCheckPayload>
    ) {
      if (state[module] === undefined) {
        state[module] = {}
      }
      state[module][id] = checked
    },
  },
})

export const { setInitialState, checkCheckbox } = actions
export default reducer
