import styled from "@emotion/styled"
import { rem } from "polished"
import Box from "@ui/Box"
import { css } from "@emotion/react"

export const centerLayoutStyle = css`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const CenterLayout = styled(Box)`
  ${centerLayoutStyle}
`

CenterLayout.defaultProps = {
  px: "md",
  maxWidth: rem("992px"),
}

export default CenterLayout
