import shouldForwardProp from "@styled-system/should-forward-prop"

import {
  color,
  ColorProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system"

import styled from "@emotion/styled"

type UIProps = ColorProps &
  SpaceProps &
  TypographyProps & {
    as?: any
  }

export const ListItem = styled("li", {
  shouldForwardProp,
})<UIProps>`
  ${space}
  ${typography}
  ${color}
`

export default ListItem
