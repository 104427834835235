import shouldForwardProp from "@styled-system/should-forward-prop"
import React from "react"
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system"

import styled from "@emotion/styled"
import { shade } from "polished"

export type StyleProps = LayoutProps & SpaceProps & ColorProps & TypographyProps

export const StyledInput = styled("input", {
  shouldForwardProp,
})<StyleProps>`
  width: 100%;

  display: flex;
  align-items: center;
  position: relative;
  font-size: 1rem;

  //  height: 2.5rem;
  //  line-height: 2.5rem;
  //  padding-left: 1rem;
  //  padding-right: 1rem;

  background-color: rgb(255, 255, 255);
  transition: all 0.2s ease-in-out;
  outline: none;

  border: 2px solid ${(props) => props.theme.colors.aquamarine};

  &::placeholder {
    color: ${(props) => props.theme.colors.grey400};
  }

  &:disabled {
    opacity: 0.35;
    pointer-events: none;
  }

  &:hover,
  &:focus {
    outline: 0;
    border-color: ${(props) => shade(0.2, props.theme.colors.aquamarine)};
  }

  &[data-invalid="true"] {
    border-color: ${(props) => props.theme.colors.error};
  }

  &[data-invalid="true"]:hover,
  &[data-invalid="true"]:focus {
    outline: 0;
    border-color: ${(props) => shade(0.2, props.theme.colors.error)};
  }

  ${layout};
  ${space};
  ${color};
  ${typography};
`

export type Props = StyleProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    children?: React.ReactNode | string
    invalid?: boolean
  }

const Input = ({
  children,
  onChange,
  value,
  name,
  id,
  invalid,
  ...props
}: Props) => {
  return (
    <StyledInput
      onChange={onChange}
      value={value}
      name={name}
      id={id}
      data-invalid={invalid}
      {...props}
    />
  )
}

Input.defaultProps = {
  px: "md",
  height: "3xl",
}

export default Input
