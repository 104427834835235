import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type Action = {
  description: string
}

export type Objective = {
  description: string
  stakeholders: string
  actions: Action[]
}

export type Strategy = {
  description: string
  objectives: Objective[]
}

type PlansState = {
  strategies: Strategy[]
}

const state: PlansState = {
  strategies: [
    {
      description: "",
      objectives: [
        {
          description: "",
          stakeholders: "",
          actions: [
            {
              description: "",
            },
          ],
        },
      ],
    },
  ],
}

export const plansSelector = (state: { plans: PlansState }) => state.plans
export const strategiesSelector = (state: { plans: PlansState }) =>
  state.plans.strategies

interface StrategyIdentifier {
  strategyIndex: number
}

type ObjectiveIdentifier = StrategyIdentifier & {
  objectiveIndex: number
}

type ActionIdentifier = ObjectiveIdentifier & {
  actionIndex: number
}

type DescribeAction = {
  description: string
}

type StakeholderAction = {
  stakeholders: string
}

const { reducer, actions } = createSlice({
  name: "plans",
  initialState: state,
  reducers: {
    createStrategy(state) {
      state.strategies.push({
        description: "",
        objectives: [
          {
            description: "",
            stakeholders: "",
            actions: [
              {
                description: "",
              },
            ],
          },
        ],
      })
    },
    createObjective(
      state,
      { payload: { strategyIndex } }: PayloadAction<StrategyIdentifier>
    ) {
      state.strategies[strategyIndex].objectives.push({
        description: "",
        stakeholders: "",
        actions: [
          {
            description: "",
          },
        ],
      })
    },
    createAction(
      state,
      {
        payload: { strategyIndex, objectiveIndex },
      }: PayloadAction<ObjectiveIdentifier>
    ) {
      state.strategies[strategyIndex].objectives[objectiveIndex].actions.push({
        description: "",
      })
    },

    describeStrategy(
      state,
      {
        payload: { strategyIndex, description },
      }: PayloadAction<StrategyIdentifier & DescribeAction>
    ) {
      state.strategies[strategyIndex].description = description
    },

    describeObjective(
      state,
      {
        payload: { strategyIndex, objectiveIndex, description },
      }: PayloadAction<ObjectiveIdentifier & DescribeAction>
    ) {
      state.strategies[strategyIndex].objectives[
        objectiveIndex
      ].description = description
    },

    updateObjectiveStakeholders(
      state,
      {
        payload: { strategyIndex, objectiveIndex, stakeholders },
      }: PayloadAction<ObjectiveIdentifier & StakeholderAction>
    ) {
      state.strategies[strategyIndex].objectives[
        objectiveIndex
      ].stakeholders = stakeholders
    },

    describeAction(
      state,
      {
        payload: { strategyIndex, objectiveIndex, actionIndex, description },
      }: PayloadAction<ActionIdentifier & DescribeAction>
    ) {
      state.strategies[strategyIndex].objectives[objectiveIndex].actions[
        actionIndex
      ].description = description
    },
  },
})

export const {
  createStrategy,
  createObjective,
  createAction,
  describeStrategy,
  describeObjective,
  describeAction,
  updateObjectiveStakeholders,
} = actions
export default reducer
