import Box from "@ui/Box"
import Icon from "@ui/Icon/Icon"
import styled from "@emotion/styled"
import Text from "@ui/Text/Text"
import { shade } from "polished"
import React from "react"

import { useAccordion } from "."

export const HeadingComponent = styled.button<{ variant: string }>`
  --bg: ${(props) => props.theme.colors.rhino};
  --bg-hover: ${(props) => shade(0.1, props.theme.colors.rhino)};
  --bg-dark: ${(props) => shade(0.25, props.theme.colors.rhino)};

  width: 100%;
  border: 0;
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.space.xs} ${(props) => props.theme.space.lg};

  color: #fff;
  cursor: pointer;
  text-decoration: none;

  transition: all 0.25s ease-in-out;
  position: relative;

  &:first-of-type {
    margin-top: 0;
  }

  :focus {
    outline: 0;
  }

  &[aria-expanded="true"] svg {
    transform: rotate(90deg);
  }

  background-color: var(--bg);

  :hover,
  :focus {
    background-color: var(--bg-hover);
  }

  :after {
    border-color: var(--bg-dark);
  }
`

const HeadingTitle = styled(Text)`
  flex: 1;
  display: flex;
  align-items: center;
`

HeadingTitle.defaultProps = {
  as: "div",
  fontSize: "md",
}

interface AccordionItemProps {
  id: string
  title: string
  children: React.ReactNode
}

const AccordionItem = ({ id, title, children }: AccordionItemProps) => {
  const { selected, setSelected } = useAccordion()

  const expanded = selected === id

  return (
    <>
      <HeadingComponent
        id={id}
        variant={id}
        aria-expanded={expanded}
        onClick={() =>
          setSelected((prevSelection) => (prevSelection === id ? null : id))
        }
      >
        <Icon name="chevronRight" size="24px" mr="2xs" />
        <HeadingTitle>{title}</HeadingTitle>
      </HeadingComponent>
      <Box hidden={!expanded} p="lg" bg="#fff" aria-labelledby={id}>
        {children}
      </Box>
    </>
  )
}

export default AccordionItem
