import React, { useState, useContext } from "react"

export interface AccordionContextProps {
  selected: string | null
  setSelected: React.Dispatch<React.SetStateAction<string | null>>
}

export const AccordionContext = React.createContext<AccordionContextProps | null>(
  null
)

export interface AccordionProps {
  children: React.ReactNode
}

export const useAccordion = (): AccordionContextProps => {
  const context = useContext(AccordionContext)

  if (!context) {
    throw Error("useAccordion needs to be used within an AccordionContext")
  }

  return context
}

const Accordion = ({ children }: AccordionProps) => {
  const [selected, setSelected] = useState<string | null>(null)

  return (
    <AccordionContext.Provider
      value={{
        selected,
        setSelected,
      }}
    >
      {children}
    </AccordionContext.Provider>
  )
}

export default Accordion
