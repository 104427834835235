import { combineReducers } from "@reduxjs/toolkit"
import checkboxSliceReducer from "./checkboxSlice"
import plansSliceReducer from "./plansSlice"
import teamsSliceReducer from "./teamsSlice"

const rootReducer = combineReducers({
  checkbox: checkboxSliceReducer,
  plans: plansSliceReducer,
  teams: teamsSliceReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
