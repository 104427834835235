import React from "react"
import InfoModal from "./info-modal"
import Text from "@ui/Text/Text"

const CoreResponsibilityModal = () => {
  return (
    <InfoModal
      title="Core team - Team Member - Responsibility"
      iconLabel="Core team - Team Member - Responsibility"
    >
      <Text>
        The development of the NDP. The team needs the capacity, authority and
        adequate resources to be able to full fill essential functions while
        developing an integrated, inclusive and participatory planning process
      </Text>
    </InfoModal>
  )
}

export default CoreResponsibilityModal
