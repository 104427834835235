exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-background-tsx": () => import("./../../../src/pages/background.tsx" /* webpackChunkName: "component---src-pages-background-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-how-to-tsx": () => import("./../../../src/pages/how-to.tsx" /* webpackChunkName: "component---src-pages-how-to-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-relevant-tools-tsx": () => import("./../../../src/pages/relevant-tools.tsx" /* webpackChunkName: "component---src-pages-relevant-tools-tsx" */),
  "component---src-templates-module-tsx": () => import("./../../../src/templates/module.tsx" /* webpackChunkName: "component---src-templates-module-tsx" */)
}

