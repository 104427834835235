import React, { useRef, useEffect } from "react"
import ReactDOM from "react-dom"
import FocusLock from "react-focus-lock"
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock"
import styled from "@emotion/styled"
import { useHotkeys } from "react-hotkeys-hook"

const ModalElement = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

interface ModalProps {
  children: React.ReactNode
  onClose?: () => void
}
const Modal = ({ children, onClose }: ModalProps) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (ref.current) {
      disableBodyScroll(ref.current)
    }
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [ref])

  useHotkeys(
    "esc",
    () => {
      if (onClose) {
        onClose()
      }
    },
    []
  )

  return ReactDOM.createPortal(
    <FocusLock returnFocus>
      <ModalElement aria-modal="true" role="dialog" ref={ref}>
        {children}
      </ModalElement>
    </FocusLock>,
    document.body
  )
}

export default Modal
