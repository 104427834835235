import React from "react"
import styled from "@emotion/styled"
import Text from "../Text/Text"

const Label = styled((props: any) => (
  <Text
    as="label"
    fontWeight="bold"
    color="aquamarine"
    display="block"
    {...props}
  />
))()

export default Label
