import { useTheme } from "@emotion/react"
import React from "react"
import {
  layout,
  space,
  SpaceProps,
  LayoutProps,
  color,
  ColorProps,
} from "styled-system"
import shouldForwardProp from "@styled-system/should-forward-prop"

import styled from "@emotion/styled"
import { Theme } from "../theme"

type StyleProps = LayoutProps & SpaceProps & ColorProps

const Svg = styled("svg", { shouldForwardProp })<StyleProps>`
  ${layout}
  ${space}
  ${color}

  flex-shrink: 0;
`

type Props = JSX.IntrinsicElements["svg"] &
  StyleProps & {
    name: keyof Theme["icons"]
  }

const Icon = ({
  name,
  size = "1em",
  color = "currentColor",
  role = "presentation",
  ...props
}: Props) => {
  const { icons } = useTheme()

  const icon = icons[name]

  return (
    <Svg
      size={size}
      color={color}
      role={role}
      viewBox={icon.viewBox}
      aria-hidden="true"
      {...props}
    >
      {icon.path}
    </Svg>
  )
}

export default Icon
