import React, { useState } from "react"
import styled from "@emotion/styled"
import Icon from "@ui/Icon/Icon"
import Modal from "@ui/Modal/Modal"
import Heading from "@ui/Heading/Heading"
import CenterLayout from "@components/centerlayout"
import { space, SpaceProps } from "styled-system"
import { rgba } from "polished"

const Dialog = styled.div<SpaceProps>`
  ${space};
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
`

const ModalClose = styled.button`
  position: absolute;
  z-index: 1010;
  right: 2rem;
  top: 2rem;
  padding: 0.5rem;
  background: ${rgba("#fff", 0.8)};
  cursor: pointer;
  box-shadow: 0px 0px 4px #fff;
  border: 0;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.grey700};
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;

  :focus {
    outline: 0;
  }

  :hover,
  :focus {
    color: #000;
  }
`

export const IconButton = styled.button<SpaceProps>`
  ${space};
  background: transparent;
  padding: 0;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-size: 1.5rem;
  transition: all 0.2s ease-in-out;
  color: ${(props) => props.theme.colors.sherpa};

  :focus {
    outline: 0;
  }

  :hover,
  :focus {
    opacity: 0.75;
  }
`

const DefaultIconButton = (props: any) => (
  <IconButton {...props}>
    <Icon name="info" display="block" />
  </IconButton>
)

const InfoModal = ({
  title,
  iconLabel,
  triggerComponent: Trigger = DefaultIconButton,
  children,
}: any) => {
  const [active, setActive] = useState<boolean>(false)

  return (
    <>
      <Trigger aria-label={iconLabel} onClick={() => setActive(true)} />
      {active && (
        <Modal aria-labelledby="modal-label" onClose={() => setActive(false)}>
          <ModalClose aria-label="Close" onClick={() => setActive(false)}>
            <Icon name="modalClose" size="32px" display="block" />
          </ModalClose>
          <Dialog p="2xl">
            <CenterLayout px="0">
              <Icon name="info" size="32px" display="block" />
              <Heading id="modal-label" my="md" fontWeight={600}>
                {title}
              </Heading>
              {children}
            </CenterLayout>
          </Dialog>
        </Modal>
      )}
    </>
  )
}

export default InfoModal
