import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type TeamMember = {
  name: string
  title: string
  teamTitle: string
  organizationalUnit: string
  responsibility: string
}

export type TeamsState = {
  core: TeamMember[]
  extended: TeamMember[]
  experts: TeamMember[]
}

const newTeamMember = (): TeamMember => ({
  name: "",
  title: "",
  teamTitle: "",
  organizationalUnit: "",
  responsibility: "",
})

const state: TeamsState = {
  core: [newTeamMember()],
  extended: [newTeamMember()],
  experts: [newTeamMember()],
}

export const teamsSelector = (state: { teams: TeamsState }) => state.teams

export type TeamName = keyof TeamsState

type UpdateTeamMemberPayload = PayloadAction<{
  teamMemberIndex: number
  team: TeamName
  field: keyof TeamMember
  value: string
}>

const { reducer, actions } = createSlice({
  name: "teams",
  initialState: state,
  reducers: {
    createTeamMember(state, { payload }: PayloadAction<TeamName>) {
      state[payload].push(newTeamMember())
    },

    updateTeamMember(
      state,
      {
        payload: { team, teamMemberIndex, field, value },
      }: UpdateTeamMemberPayload
    ) {
      state[team][teamMemberIndex][field] = value
    },
  },
})

export const { createTeamMember, updateTeamMember } = actions
export default reducer
