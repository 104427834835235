import React from "react"
import InfoModal from "./info-modal"
import Text from "@ui/Text/Text"

const ExpertNetworkModal = () => {
  return (
    <InfoModal title="Expert Network" iconLabel="Expert Network">
      <Text>
        Relevant stakeholders e.g. representatives from Parliament, Civil
        Society, private sector, academia, interest organisations etc.
      </Text>
    </InfoModal>
  )
}

export default ExpertNetworkModal
