import Text from "@ui/Text/Text"
import React from "react"
import InfoModal from "./info-modal"

const CoreTeamModal = () => {
  return (
    <InfoModal title="Core team" iconLabel="Core team information">
      <Text mb="2xl">
        The Core Team is typically made up of Planning Unit staff. A Planning
        Unit usually has the right mix of skills, expertise and knowledge needed
        to lead the strategic planning process, to accumulate, aggregate and
        arrange all the necessary information for the Context Analysis (Module
        2), to facilitate the scenario-building process (Module 3), formulate
        the National Development Vision (Module 4), design the Strategic Plan
        (Module 5), and to assist in developing the implementation plan (Module
        6) and define the results framework (Module 7).
      </Text>

      <Text mb="2xl">
        The Core Team is responsible for the development of the NDP. The team
        needs the capacity, authority and adequate resources to be able to
        fulfil essential functions while developing an integrated, inclusive and
        participatory planning process. In addition to ‘traditional’ planning
        skills, the team should have the capacity that can ensure a balanced
        approach to integrating the environmental, economic and social field,
        including the principle of ‘leaving no one behind’ and gender
        mainstreaming. In order to ensure a balanced and effective planning
        process, the team should consist of focal points of the 2063 Agenda, the
        2030 agenda, the climate commitments (NDCs) and DRR as well as a
        capacity in statistics and data collection.
      </Text>

      <Text mb="2xl">
        The team should be managed by a team leader. The role of the team leader
        – which is usefully the Head of the Planning Unit - is to lead the staff
        and manage the resources of the team to meet the planning process goals.
        The main responsibilities of the team leader are to: i) monitor
        progress, ensuring that deadlines and objectives are met; ii) build
        commitment and confidence in team members; iii) establish constructive
        links with other units, departments/authorities and with external
        stakeholders; iv) Inspire, lead, coach and develop; and v) take on
        responsibility for producing specific pieces of work, as appropriate.
      </Text>
      <Text mb="2xl">
        The Core Team should be empowered to work in close contact with relevant
        government ministries, agencies and other stakeholders that are included
        in the ‘Extended Team’ and the ‘Expert Network’.{" "}
      </Text>
    </InfoModal>
  )
}

export default CoreTeamModal
