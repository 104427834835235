import React from "react"
import InfoModal from "./info-modal"
import Text from "@ui/Text/Text"

const ExtendedResponsibilityModal = () => {
  return (
    <InfoModal
      title="Extended Team - Team Member - Responsibility"
      iconLabel="Extended Team - Team Member - Responsibility"
    >
      <Text>
        Providing input and knowledge sharing, ensuring public interests are
        included, awareness raising, partnership development as well as
        contribute to putting knowledge and action in practice.
      </Text>
    </InfoModal>
  )
}

export default ExtendedResponsibilityModal
