import React, { Fragment } from "react"
import Text from "@ui/Text/Text"
import Link from "@ui/Link/Link"
import Input from "@ui/Input/Input"
import Button from "@ui/Button/Button"
import Label from "@ui/Form/Label"
import { useSelector, useDispatch } from "react-redux"
import styled from "@emotion/styled"
import HorizontalRule from "@ui/HorizontalRule/HorizontalRule"
import { PDFDownloadLink } from "@react-pdf/renderer"
import {
  teamsSelector,
  updateTeamMember,
  TeamMember,
  createTeamMember,
  TeamName,
} from "@ducks/teamsSlice"
import { Dispatch } from "redux"
import Textarea from "@ui/Textarea/Textarea"
import TeamsPdf from "./TeamsPdf"
import CoreTeamModal from "@components/modals/core-team"
import Box from "@ui/Box"
import CoreResponsibilityModal from "@components/modals/core-responsibility-modal"
import ExpertNetworkResponsibilityModal from "@components/modals/expert-network-responsibility"
import ExtendedResponsibilityModal from "@components/modals/extended-responsibility-modal"
import ExtendedTeamModal from "@components/modals/extended-team"
import ExpertNetworkModal from "@components/modals/expert-network"

const CreateLink = styled(Link)`
  display: inline-block;
  color: ${props => props.theme.colors.sherpa};
  border: 0;
`

CreateLink.defaultProps = {
  mt: "2xs",
}

const DownloadLink = styled(PDFDownloadLink)`
  text-decoration: none;
`

const FormGroup = styled(Box)<{ alignCenter?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 2rem;
  align-items: ${props => (props.alignCenter ? `center` : `flex-start`)};
  grid-gap: 0.5rem;
`

const fields: Partial<TeamMember> = {
  name: "Name",
  title: "Title",
  teamTitle: "Team Title",
  organizationalUnit: "Ministry/Department",
}

const fieldNames: (keyof TeamMember)[] = Object.keys(
  fields
) as (keyof TeamMember)[]

const renderResponsibilityModal = (team: string) => {
  switch (team) {
    case "core":
      return <CoreResponsibilityModal />
    case "extended":
      return <ExtendedResponsibilityModal />
    case "experts":
      return <ExpertNetworkResponsibilityModal />
    default:
      return null
  }
}

const Team = ({
  team,
  members,
  fieldDescriptions,
  dispatch,
}: {
  team: TeamName
  members: TeamMember[]
  fieldDescriptions: Partial<TeamMember>
  dispatch: Dispatch
}) => (
  <>
    {members.map((teamMember, teamMemberIndex) => (
      <Fragment key={teamMemberIndex}>
        <Label mt="md" htmlFor={`team-${team}-name-${teamMemberIndex}`}>
          Team Member {teamMemberIndex + 1}
        </Label>
        {fieldNames.map(field => (
          <FormGroup mt="2xs" key={field}>
            <Input
              placeholder={`Team Member ${teamMemberIndex + 1} - ${
                fieldDescriptions[field]
              }`}
              id={`team-${team}-${field}-${teamMemberIndex}`}
              value={teamMember[field]}
              onChange={evt =>
                dispatch(
                  updateTeamMember({
                    team,
                    field,
                    teamMemberIndex,
                    value: evt.target.value,
                  })
                )
              }
            />
          </FormGroup>
        ))}
        <FormGroup mt="2xs">
          <Textarea
            placeholder="Responsibility"
            value={teamMember.responsibility}
            onChange={evt =>
              dispatch(
                updateTeamMember({
                  team,
                  field: "responsibility",
                  teamMemberIndex,
                  value: evt.target.value,
                })
              )
            }
          />
          {renderResponsibilityModal(team)}
        </FormGroup>
      </Fragment>
    ))}
  </>
)

const TeamsMatrixGenerator = () => {
  const { core, extended, experts } = useSelector(teamsSelector)
  const dispatch = useDispatch()

  return (
    <Box mt="3xl">
      {/* Core Team */}
      <FormGroup alignCenter>
        <Text color="sherpa" fontSize="xl" fontWeight="bold">
          Core Team
        </Text>
        <CoreTeamModal />
      </FormGroup>
      <Team
        dispatch={dispatch}
        team="core"
        members={core}
        fieldDescriptions={{
          ...fields,
          organizationalUnit: "Ministry/Department",
        }}
      />
      <CreateLink onClick={() => dispatch(createTeamMember("core"))}>
        + add a core member
      </CreateLink>
      <HorizontalRule />
      {/* Extended Team */}
      <FormGroup mt="3xl" alignCenter>
        <Text color="sherpa" fontSize="xl" fontWeight="bold">
          Extended Team
        </Text>
        <ExtendedTeamModal />
      </FormGroup>
      <Team
        dispatch={dispatch}
        team="extended"
        members={extended}
        fieldDescriptions={{
          ...fields,
          organizationalUnit: "Goverment Entity/Organization",
        }}
      />
      <CreateLink onClick={() => dispatch(createTeamMember("extended"))}>
        + add an extended team member
      </CreateLink>
      <HorizontalRule />
      {/* Expert Network */}
      <Text
        color="sherpa"
        fontSize="xl"
        mt="3xl"
        fontWeight="bold"
        style={{ textTransform: "uppercase" }}
      >
        Technical Working Groups
      </Text>
      <FormGroup alignCenter>
        <Text color="sherpa" fontSize="xl" fontWeight="bold">
          Expert Network
        </Text>
        <ExpertNetworkModal />
      </FormGroup>
      <Team
        dispatch={dispatch}
        team="experts"
        members={experts}
        fieldDescriptions={{
          ...fields,
          organizationalUnit: "Government Entity/Organization/Business",
        }}
      />
      <CreateLink onClick={() => dispatch(createTeamMember("experts"))}>
        + add an expert network member
      </CreateLink>
      <HorizontalRule />
      <DownloadLink
        document={
          <TeamsPdf core={core} extended={extended} experts={experts} />
        }
        fileName="teams-matrix.pdf"
      >
        {({ loading, error }) => (
          <Button as="div" variant="primary">
            {loading ? "Loading document..." : "Generate and download your PDF"}
          </Button>
        )}
      </DownloadLink>
    </Box>
  )
}

export default TeamsMatrixGenerator
