import styled from "@emotion/styled"
import shouldForwardProp from "@styled-system/should-forward-prop"

import {
  layout,
  space,
  typography,
  color,
  flexbox,
  SpaceProps,
  shadow,
  ShadowProps,
  LayoutProps,
  FlexboxProps,
  TypographyProps,
  ColorProps,
} from "styled-system"

// The styled-system color prop types conflicts with the color prop on the HTMLElement
// so we set it to any to bypass typescript errors since we know it works.
// @see https://spectrum.chat/styled-components/general/typescript-styled-system-styled-components~5593dbf2-7588-4c6d-9e76-b4eadd77ec90
export type BoxProps = LayoutProps &
  SpaceProps &
  TypographyProps &
  ShadowProps &
  ColorProps &
  FlexboxProps & { color?: any }

const Box = styled("div", { shouldForwardProp })<BoxProps>(
  flexbox,
  layout,
  space,
  typography,
  color,
  shadow
)

export default Box
