import React from "react"
import shouldForwardProp from "@styled-system/should-forward-prop"

import {
  color,
  ColorProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  layout,
  LayoutProps,
} from "styled-system"

import styled from "@emotion/styled"

type UIProps = ColorProps &
  SpaceProps &
  TypographyProps &
  LayoutProps & {
    as?: any
  }

const StyledText = styled("p", {
  shouldForwardProp,
})<UIProps>`
  margin: 0;

  ${space}
  ${typography}
  ${color}
  ${layout}
`

export type TextProps = React.HTMLAttributes<HTMLElement> &
  UIProps & { size?: string }

const Text = styled(
  React.forwardRef(
    (
      { size = "md", ...props }: TextProps,
      ref: React.Ref<HTMLParagraphElement>
    ) => {
      return <StyledText ref={ref} fontSize={size} {...props} />
    }
  )
)()

export default Text
